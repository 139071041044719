<template>
  <div>
    <KTPortlet>
      <template v-slot:title>
        <!-- <h3 class="kt-portlet__head-title">Add New Purchase Target</h3> -->
      </template>
      <template v-slot:body>
        <b-tabs content-class="mt-3" lazy>
          <b-tab title="Information">
            <Information></Information>
            <b-nav tabs>
              <b-nav-item disabled>Search Article</b-nav-item>
            </b-nav>
            <b-row>
              <b-col md="6" class="my-1">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label="Search Mode"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="search_mode"
                >
                  <b-form-radio-group
                    id="search_mode"
                    v-model="search_mode"
                    :options="options.search_mode"
                    name="radio-options"
                  ></b-form-radio-group
                ></b-form-group>
              </b-col>
            </b-row>
            <BrandArticleNumber v-if="search_mode == 0"></BrandArticleNumber>
            <!-- <Link v-if="search_mode == 1"></Link> -->
          </b-tab>
          <b-tab title="logs"> </b-tab>
        </b-tabs>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';
import Information from '@/views/content/NewAdd/Information/Index';
import BrandArticleNumber from '@/views/content/NewAdd/Information/BrandArticleNumber';
// import Link from '@/views/content/NewAdd/Information/Link';
export default {
  name: 'purchaseAdd',
  components: { KTPortlet, Information, BrandArticleNumber },
  data() {
    return {
      search_mode: 0,
      options: {
        search_mode: [
          { text: 'Brand Article Number', value: 0 },
          { text: 'Link', value: 1 }
        ]
      }
    };
  },

  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped></style>
