<template>
  <b-row>
    <b-col md="6" class="my-1">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="Order ID"
        label-align-sm="right"
        label-size="sm"
        label-for="order_id"
      >
        <b-form-input
          v-model="init.order_id"
          :type="'text'"
          :placeholder="this.$t('page.please_choose_input')"
          id="order_id"
          disabled
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="Status"
        label-align-sm="right"
        label-size="sm"
        label-for="status"
      >
        111
      </b-form-group>
    </b-col>
    <b-col md="6" class="my-1 select-box">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="Status"
        label-align-sm="right"
        label-size="sm"
        label-for="status"
        class="required"
      >
        <div class="select-overlay" v-if="options.status == 0">
          <b-spinner small type="grow"></b-spinner>
          <b-spinner small type="grow"></b-spinner>
          <b-spinner small type="grow"></b-spinner>
          <b-spinner small type="grow"></b-spinner>
        </div>
        <sv-select
          v-model="zhang.status"
          :placeholder="
            options.status == 0 ? $t('page.loading') : $t('page.please_choose')
          "
          :clearable="false"
          :options="options.status"
          :reduce="options => options.value"
          :disabled="options.status == 0"
        ></sv-select>
      </b-form-group>
    </b-col>
    <b-col md="6" class="my-1 select-box">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="Applicant"
        label-align-sm="right"
        label-size="sm"
        label-for="applicant"
        class="required"
        v-if="zhang.status && zhang.status != '0'"
      >
        <div class="select-overlay" v-if="options.applicant == 0">
          <b-spinner small type="grow"></b-spinner>
          <b-spinner small type="grow"></b-spinner>
          <b-spinner small type="grow"></b-spinner>
          <b-spinner small type="grow"></b-spinner>
        </div>

        <sv-select
          v-model="zhang.applicant"
          :placeholder="
            options.applicant == 0
              ? $t('page.loading')
              : $t('page.please_choose')
          "
          :options="options.applicant"
          :reduce="options => options.value"
          :disabled="options.applicant == 0"
        ></sv-select>
      </b-form-group>
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="Amount"
        label-align-sm="right"
        label-size="sm"
        label-for="amount"
        class="required"
      >
        <b-form-input
          v-model="zhang.amount"
          :type="'text'"
          :placeholder="this.$t('page.please_choose_input')"
          id="amount"
        ></b-form-input
      ></b-form-group>
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="Sales Price"
        label-align-sm="right"
        label-size="sm"
        label-for="sales_price"
        class="required"
      >
        <b-input-group>
          <template v-slot:append>
            <b-input-group-text>
              <strong>€</strong>
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="zhang.sales_price"
            :type="'text'"
            :placeholder="this.$t('page.please_choose_input')"
            id="sales_price"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Information',

  data() {
    return {
      init: {
        order_id: null
      },
      zhang: {
        status: '0',
        applicant: null,
        sales_price: null
      },

      options: {
        status: [
          { label: 'Please select', value: '0' },
          { label: 'Pre Order', value: 'pre_order' },
          { label: 'Business Distributor', value: 'business_distributor' },
          { label: 'Private Distributor', value: 'private_distributor' }
        ],
        applicant: []
      }
    };
  },
  props: {},
  components: {},
  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped></style>
